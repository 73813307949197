import React, { useEffect, useState, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {FbrUsersCountWidget} from "./components/UsersCountWidget"
import * as actions from "./_redux/dashboardActions";

export function FbrDashboard() {
    const dispatch = useDispatch();
    const { proFreeChartData, overallUsersChartData, proPayedChartData } = useSelector(
        state => {
            return {
                proFreeChartData : state.dashboard.userData.proFree,
                proPayedChartData: state.dashboard.userData.proPayed,
                overallUsersChartData: state.dashboard.userData.main
            }
        },
        shallowEqual
    );
    useEffect(() => {
        dispatch(actions.fetchGroupUsersCount('main'));
        dispatch(actions.fetchGroupUsersCount('proFree'));
        dispatch(actions.fetchGroupUsersCount('proPayed'));
    }, []);
/*
    useEffect(() => {}, [proFreeChartData]);*/
    return     <>
        <div className="row">
            <div className="col-lg-6 col-xxl-6 pt-2">
                <FbrUsersCountWidget name={'proFree'} title={'Pro Trial Users'}  chartData={proFreeChartData}/>
            </div>
            <div className="col-lg-6 col-xxl-6 pt-2">
                <FbrUsersCountWidget name={'proPayed'} title={'Pro Payed Users'}  chartData={proPayedChartData}/>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 col-xxl-12 pt-2">
                <FbrUsersCountWidget name={'main'} title={'Overall Users'} chartData={overallUsersChartData}/>
            </div>

        </div>
    </>
}
