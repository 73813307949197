// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { onApprove, onDecline }
) {
    if (row.status !== 'verified' && row.status !== 'declined') return getActivatedActions(row, onApprove, onDecline);
    return null;
}

function getActivatedActions(row, onApprove, onDecline) {
   return (
        <>
            <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => onApprove(row._id)}
            >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
              src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
          />
        </span>
            </a>
            <> </>

            <a
                title="Delete customer"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => onDecline(row._id)}
            >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")} />
        </span>
            </a>
        </>
    );
}
