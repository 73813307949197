import {useHtmlClassService} from "../../../../_metronic/layout";
import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import * as _ from "lodash";

export function FbrUsersCountWidget(props) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBasePrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.base.primary"
            ),
            colorsThemeLightPrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.light.primary"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        if (props.chartData && props.name) {
            const element = document.getElementById(`kt_stats_widget_${props.name}_chart`);
            if (!element) {
                return;
            }
            const options = getChartOption(layoutProps, props);
            const chartnewUsers = new ApexCharts(element, options);
            chartnewUsers.render();
            return function cleanUp() {
                chartnewUsers.destroy();
            };
        }
    }, [props, layoutProps]);

    return (
        <div className={`card card-custom`}>
            <div className="card-body d-flex flex-column p-0">
                <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
            <span className="symbol-label">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Group.svg"
                    )}
                ></SVG>
              </span>
            </span>
          </span>
                    <div className="d-flex flex-column text-right">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
              {props.chartData && props.chartData.length && props.chartData[props.chartData.length - 1].count}
            </span>
                        <span className="text-muted font-weight-bold mt-2">{props.title}</span>
                    </div>
                </div>
                <div
                    id={`kt_stats_widget_${props.name}_chart`}
                    className="card-rounded-bottom"
                    style={{ height: "300px" }}
                ></div>
            </div>
        </div>
    );
}

function getChartOption(layoutProps, props) {
    const data = props.chartData;
    const title = data.title;
    const dataPerDay = data.map(row => ({
        ...row,
        date: new Date(row.date).toLocaleString().split(',')[0]
    }));
    const chartData = _.uniqBy(dataPerDay, (v) => v.date);
    var options = {
        series: [
            {
                name: title,
                data: chartData.map(row => Number(row.count))
            }
        ],
        chart: {
            type: "line",
            height: 300,
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true
        },
        dataLabels: {
            enabled: true
        },
        fill: {
            type: "solid",
            opacity: 1
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: [layoutProps.colorsThemeBasePrimary]
        },
        xaxis: {
            categories: chartData.map(row => row.date),
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: true
            },
            labels: {
                show: true,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            },
            crosshairs: {
                show: false,
                position: "front",
                stroke: {
                    color: layoutProps.colorsGrayGray300,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            },
            title: {
                text: 'Day'
            }
        },
        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily
            }
        },
        colors: [layoutProps.colorsThemeLightPrimary],
        markers: {
            colors: [layoutProps.colorsThemeLightPrimary],
            strokeColor: [layoutProps.colorsThemeBasePrimary],
            strokeWidth: 3
        }
    };
    return options;
}
