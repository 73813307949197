import React, { useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {usePromoCodesUIContext} from "./PromoCodesUIContext";
import {PromoCodesTable} from "./promocodes-table/PromoCodesTable";


export function PromoCodesCard() {
    const promoCodesUIContext = usePromoCodesUIContext();
    const promoCodesUIProps = useMemo(() => {
        return {
            newPromoCodeButtonClick: promoCodesUIContext.newPromoCodeButtonClick,
        };
    }, [promoCodesUIContext]);
    return (
        <Card>
            <CardHeader title="Promo Codes">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={promoCodesUIProps.newPromoCodeButtonClick}
                    >
                        New Promo Code
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <PromoCodesTable></PromoCodesTable>
            </CardBody>
        </Card>
    );
}
