import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {NewPromoCodeForm} from "./NewPromoCodeForm";
import {usePromoCodesUIContext} from "../PromoCodesUIContext";
import * as actions from "../_redux/promoCodesActions";
export function NewPromoCodeDialog({ id, show, onHide }) {

    const promoCodesUIContext = usePromoCodesUIContext();
    const promoCodesUIProps = useMemo(() => {
        return {
            initPromoCode: promoCodesUIContext.initPromoCode,
        };
    }, [promoCodesUIContext]);
    const dispatch = useDispatch();
    const savePromoCode = (promoCode) => {
        dispatch(actions.addPromoCode(promoCode)).then(() =>  onHide());
    }
    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Add new promo code</Modal.Title>
            </Modal.Header>
            <NewPromoCodeForm
                promoCode={promoCodesUIProps.initPromoCode}
                savePromoCode={savePromoCode}
                onHide={onHide}
            ></NewPromoCodeForm>
        </Modal>
    )
}
