import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./InvoicesUIHelpers";
const InvoicesUIContext = createContext();

export function useInvoicesUIContext() {
    return useContext(InvoicesUIContext);
}

export function InvoicesUIProvider({customersUIEvents, children}) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);


    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams
    };

    return <InvoicesUIContext.Provider value={value}>{children}</InvoicesUIContext.Provider>;
}
