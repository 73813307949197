import React, {useEffect, useMemo, useState} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "../_redux/promoCodesCrud";
export function PromoCodesUserPage({
                                       history,
                                       match: {
                                           params: { email },
                                       }
    }) {
    const [compensation, setCompensation] = useState(0);

    useEffect(() => {
        let mounted = true;
        requestFromServer.getOverallCompensationByEmail(email).then((val) => {
            setCompensation(val.data.compensation);
        })
        return () => mounted = false;
    }, [])

    return (<>
        <Card>
            <CardHeader title={email}>
            </CardHeader>
            <CardBody>
                {compensation/ 100}$
            </CardBody>
        </Card>
    </>)
}
