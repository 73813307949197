import {callTypes, invoicesSlice} from "./invoicesSlice";
import * as requestFromServer from "./invoicesCrud";

const {actions} = invoicesSlice;


export const fetchInvoices = qp => (dispatch,getState) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    const queryParams = qp === undefined ? getState().invoices.lastFilter : qp;
    return requestFromServer
        .getInvoices(queryParams)
        .then(response => {
            const {entities, totalCount} = response.data;
            dispatch(actions.invoicesFetched({ entities, totalCount, lastFilter: queryParams}));
        })
};

export const approveInvoice = (id) => (dispatch, getState) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .approveInvoice(id)
        .then(response => {
            dispatch(fetchInvoices());
        })
}

export const declineInvoice = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .declineInvoice(id)
        .then(response => {
            dispatch(fetchInvoices());
        })
}
