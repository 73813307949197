import {createSlice} from "@reduxjs/toolkit";

const initialDashboardState = {
    userData: {}
};


export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialDashboardState,
    reducers: {
        usersCountFetched: (state, action) => {
            state.userData = {
                ...state.userData,
                [action.payload.group]: action.payload.data
            };
        }
    }
});
