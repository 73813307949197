import React from "react";
import { InvoicesCard } from "./InvoicesCard"
import { InvoicesUIProvider } from "./InvoicesUIContext";

export function InvoicesPage() {


    return (
        <InvoicesUIProvider>
            <InvoicesCard/>
        </InvoicesUIProvider>
    )

    /* (<div className="card">
        <div className="card-header">
            <div className="card-title">
                <div className="card-label">
                    Invoices
                </div>
            </div>
        </div>
        <div className="card-body">
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField='_id'
                data={ (currentState && currentState.entities) ? currentState.entities : [] }
                columns={ columns }/>
        </div>
    </div>)*/
}
