import axios from "axios";
export const PROMO_CODES_URL = "admin/promo-codes";

export function getPromoCodes(filter) {
    return axios.get(PROMO_CODES_URL, {
        params: {
            limit: filter.pageSize,
            offset: (filter.pageNumber - 1) * filter.pageSize
        }
    });
}

export function addPromoCode(promoCode) {
    return axios.post(PROMO_CODES_URL, promoCode);
}

export function getOverallCompensationByEmail(email) {
    return axios.get(`${PROMO_CODES_URL}/compensation/${email}`);
}
