import React, {createContext, useContext, useState, useCallback} from "react";
import {initialFilter} from "./PromoCodesUIHelpers";
import {isEqual, isFunction} from "lodash";
const PromoCodesUIContext = createContext();

export function usePromoCodesUIContext() {
    return useContext(PromoCodesUIContext);
}

export function PromoCodesUIProvider({promoCodesUIEvents, children}) {

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initPromoCode = {
        id: undefined,
        email: "",
        promoCode: "",
        discount: 10,
        compensation: 20,
        validUntilDate: null
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initPromoCode,
        newPromoCodeButtonClick: promoCodesUIEvents.newPromoCodeButtonClick,
    };
    return <PromoCodesUIContext.Provider value={value}>{children}</PromoCodesUIContext.Provider>;
}
