import {createSlice} from "@reduxjs/toolkit";

const initialPromoCodesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    customerForEdit: undefined,
    lastError: null,
    lastFilter: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const promoCodesSlice = createSlice({
    name: "promoCodes",
    initialState: initialPromoCodesState,
    reducers: {
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        promoCodesFetched: (state, action) => {
            const {entities, totalCount, lastFilter} = action.payload
            state.entities = entities;
            state.listLoading = false;
            state.totalCount = totalCount;
            state.lastFilter = lastFilter;
        }
    }
})
