import * as requestFromServer from "./dashboardCrud";
import {dashboardSlice} from "./dashboardRedux";

const {actions} = dashboardSlice;

export const fetchGroupUsersCount = (groupName) => dispatch => {
    return requestFromServer
        .getUsersCountByGroup(groupName)
        .then(response => {
            dispatch(actions.usersCountFetched({ data: response.data, group: groupName}));
        })
};
