import {createSlice} from "@reduxjs/toolkit";

const initialInvoicesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    customerForEdit: undefined,
    lastError: null,
    lastFilter: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const invoicesSlice = createSlice({
    name: "invoices",
    initialState: initialInvoicesState,
    reducers: {
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        invoicesFetched: (state, action) => {
            const { totalCount, entities, lastFilter } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.lastFilter = lastFilter;
        }
    }
});
