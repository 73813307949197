import React, { useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import {InvoicesFilter} from './invoices-filter/InvoicesFilter'
import {InvoicesTable} from "./invoices-table/InvoicesTable";

export function InvoicesCard() {
    return (
        <Card>
            <CardHeader title="Invoices">
            </CardHeader>
            <CardBody>
                <InvoicesFilter />
                <InvoicesTable />
            </CardBody>
        </Card>
    );
}
