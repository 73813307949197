import React, {useEffect, useMemo} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../../_redux/invoicesActions";
import {UsersColumnFormatter} from "./column-formatters/UsersColumnFormatter";
import {StatusColumnFormatter} from "./column-formatters/StatusColumnFormatter";
import {ActionsColumnFormatter} from "./column-formatters/ActionsColumnFormatter";
import {InvoicePriceColumnFormatter} from "./column-formatters/PriceColumnFormatter";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import * as uiHelpers from "../InvoicesUIHelpers";
import {useInvoicesUIContext} from "../InvoicesUIContext";
import {
    getHandlerTableChange,
} from "../../../../../_metronic/_helpers";

export function InvoicesTable() {
    const dispatch = useDispatch();
    const invoicesUIContext = useInvoicesUIContext();
    const invoicesUIProps = useMemo(() => {
        return {
            queryParams: invoicesUIContext.queryParams,
            setQueryParams: invoicesUIContext.setQueryParams,
        };
    }, [invoicesUIContext]);
    const onApprove = (id) => {
        dispatch(actions.approveInvoice(id))
    }
    const onDecline = (id) => {
        dispatch(actions.declineInvoice(id))
    }
    const columns = [{
        dataField: '_id',
        text: 'ID'
    }, {
        dataField: 'userId',
        text: 'User',
        formatter: UsersColumnFormatter
    }, {
        dataField: 'userEmail',
        text: 'Email'
    }, {
        dataField: 'price',
        text: 'Price',
        formatter: InvoicePriceColumnFormatter
    }, {
        dataField: 'type',
        text: 'Payment type',
    }, {
        dataField: 'status',
        text: 'Status',
        formatter: StatusColumnFormatter
    },{
        dataField: 'promoCode',
        text: 'Promo code'
    },{
        dataField: 'createdAt',
        text: 'Created Date'
    }, {
        dataField: "action",
        text: "Actions",
        formatExtraData: {
            onApprove: onApprove,
            onDecline: onDecline
        },
        formatter: ActionsColumnFormatter,
    }];


    const { currentState } = useSelector(
        (state) => ({ currentState: state.invoices }),
        shallowEqual
    );

    const { totalCount, listLoading, entities } = currentState;


    useEffect(() => {
        dispatch(actions.fetchInvoices(invoicesUIProps.queryParams));
    }, [invoicesUIProps.queryParams, dispatch]);

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: invoicesUIContext.queryParams.pageSize,
        page: invoicesUIContext.queryParams.pageNumber,
    };


    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                remote
                                wrapperClasses="table-responsive"
                                bordered={false}
                                loading={listLoading}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                keyField='_id'
                                data={ (entities && entities.length) ? entities : [] }
                                onTableChange={getHandlerTableChange(
                                    invoicesUIProps.setQueryParams
                                )}
                                noDataIndication={() => (<div>No Invoices found</div>)}
                                overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                                columns={ columns }
                                {...paginationTableProps}
                            >
                            </BootstrapTable>
                        </Pagination>
                    )}}
            </PaginationProvider>
        </>
    );
}

