import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {usePromoCodesUIContext} from "../PromoCodesUIContext";
import React, {useEffect, useMemo} from "react";
import * as actions from "../../PromoCodes/_redux/promoCodesActions";
import BootstrapTable from "react-bootstrap-table-next";
import {getHandlerTableChange} from "../../../../_metronic/_helpers";
import overlayFactory from "react-bootstrap-table2-overlay";
import {Pagination} from "../../../../_metronic/_partials/controls";
import * as uiHelpers from "../PromoCodesUIHelpers";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Link} from "react-router-dom";

export function PromoCodesTable() {
    const dispatch = useDispatch();
    const promoCodesUIContext = usePromoCodesUIContext();
    const promoCodesUIProps = useMemo(() => {
        return {
            queryParams: promoCodesUIContext.queryParams,
            setQueryParams: promoCodesUIContext.setQueryParams,
        };
    }, [promoCodesUIContext]);
    const columns = [{
        dataField: 'promoCode',
        text: 'Promo Code'
    }, {
        dataField: 'email',
        text: 'Email',
        formatter: (row) => ( <Link to={'promo-codes/by-email/' + row}>{row}</Link>)
    }, {
        dataField: 'discount',
        text: 'Discount',
        formatter: (row) => (<span>{row}%</span>)
    }, {
        dataField: 'compensation',
        text: 'Compensation',
        formatter: (row) => (<span>{row}%</span>)
    }, {
        dataField: 'validUntilDate',
        text: 'Valid Until'
    }];

    useEffect(() => {
        dispatch(actions.fetchPromoCodes(promoCodesUIProps.queryParams));
    }, [promoCodesUIProps.queryParams, dispatch]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.promoCodes }),
        shallowEqual
    );

    const { entities, listLoading, totalCount } = currentState;

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: promoCodesUIContext.queryParams.pageSize,
        page: promoCodesUIContext.queryParams.pageNumber,
    };
    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                remote
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                keyField='_id'
                                data={ (entities && entities.length) ? entities : [] }
                                loading={listLoading}
                                onTableChange={getHandlerTableChange(
                                    promoCodesUIProps.setQueryParams
                                )}
                                noDataIndication={() => (<div>No Promo codes found</div>)}
                                overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                                columns={ columns }
                                {...paginationTableProps}
                            >
                            </BootstrapTable>
                        </Pagination>
                    )
                }}
            </PaginationProvider>
        </>

    )
}
