export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.authorization = authToken;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {

      if (!error.response || error.response.status === 401) {
          window.location.href = '/logout';
      }
      return Promise.reject(error);
    });
}
