import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {dashboardSlice} from "../app/modules/Dashboard/_redux/dashboardRedux";
import {invoicesSlice} from "../app/modules/Invoices/_redux/invoicesSlice";
import {promoCodesSlice} from "../app/modules/PromoCodes/_redux/promoCodesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: dashboardSlice.reducer,
  invoices: invoicesSlice.reducer,
  promoCodes: promoCodesSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
