import React, { useMemo } from "react";
import { Formik } from "formik";
import {useDispatch} from "react-redux";
import {useInvoicesUIContext} from "../InvoicesUIContext";
import {isEqual} from "lodash";

const prepareFilter = (queryParams, values) => {
    const { status, searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    // Filter by status
    filter.status = status !== "" ? status : undefined;
    filter.email = searchText !== "" ? searchText : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
};


export function InvoicesFilter() {
    const dispatch = useDispatch();
    const invoicesUIContext = useInvoicesUIContext();
    const invoicesUIProps = useMemo(() => {
        return {
            queryParams: invoicesUIContext.queryParams,
            setQueryParams: invoicesUIContext.setQueryParams,
        };
    }, [invoicesUIContext]);
    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(invoicesUIProps.queryParams, values);
        if (!isEqual(newQueryParams, invoicesUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            // update list by queryParams
            invoicesUIProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    searchText: "",
                }}
                onSubmit={(values) => {
                    applyFilter(values);
                }}
            >
                {({
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className="col-lg-2">
                                <select
                                    className="form-control"
                                    name="status"
                                    placeholder="Filter by Status"
                                    // TODO: Change this code
                                    onChange={(e) => {
                                        setFieldValue("status", e.target.value);
                                        handleSubmit();
                                    }}
                                    onBlur={handleBlur}
                                    value={values.status}
                                >
                                    <option value="">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="activated">Actived</option>
                                    <option value="verified">Verified</option>
                                    <option value="created">Created</option>
                                    <option value="declined">Declined</option>
                                </select>
                                <small className="form-text text-muted">
                                    <b>Filter</b> by Status
                                </small>
                            </div>
                            <div className="col-lg-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Search"
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        handleSubmit();
                                    }}
                                />
                                <small className="form-text text-muted">
                                    <b>Search</b> by email
                                </small>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}
