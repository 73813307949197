import React from "react";
import {PromoCodesCard} from "./PromoCodesCard";
import {PromoCodesUIProvider} from "./PromoCodesUIContext";
import {Route} from "react-router-dom";
import {NewPromoCodeDialog} from "./new-promocode-dialog/NewPromoCodeDialog";

export function PromoCodesPage({ history }) {
    const promoCodesUiEvents = {
        newPromoCodeButtonClick: () => {
            history.push("/promo-codes/new");
        }
    }
    return (
        <PromoCodesUIProvider promoCodesUIEvents={promoCodesUiEvents}>
            <Route path="/promo-codes/new">
                {({ history, match }) => (
                    <NewPromoCodeDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/promo-codes");
                        }}
                    />
                )}
            </Route>
            <PromoCodesCard/>
        </PromoCodesUIProvider>
    );
}
