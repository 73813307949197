import React from "react";
import {Link} from "react-router-dom";

const CustomerStatusCssClasses = {
    pending: "info",
    Pending: "info",
    activated: "success",
    declined: "danger"
}

export function UsersColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-lg label-light-${
            CustomerStatusCssClasses[row.status]
        } label-inline`;
    };
    return (
        <Link to={'users/' + row.userId + '/view'}>{row.userId}</Link>
    );
}
