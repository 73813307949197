import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Input,
    Select,
    DatePickerField,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const NewPromoCodeSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    promoCode: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Promo code is required"),
    discount: Yup.number()
        .required("Discount is required")
        .min(0)
        .max(100),
    compensation: Yup.number()
        .required("Compensation is required")
        .min(0)
        .max(100),
    validUntilDate: Yup.mixed()
});


export function NewPromoCodeForm({
                                     promoCode,
                                     savePromoCode,
                                     onHide,
                                 }) {
    return (
        <>
            <Formik
                validationSchema={NewPromoCodeSchema}
                enableReinitialize={true}
                initialValues={promoCode}
                onSubmit={(values) => {
                    savePromoCode(values);
                }}
            >
                {({ handleSubmit }) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <Field
                                            type="email"
                                            name="email"
                                            component={
                                                Input
                                            }
                                            placeholder="Email"
                                            label="Email"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <Field
                                            name="promoCode"
                                            component={
                                                Input
                                            }
                                            placeholder="Promo Code"
                                            label="Promo Code"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            type="number"
                                            name="discount"
                                            component={
                                                Input
                                            }
                                            placeholder="Discount(%)"
                                            label="Discount(%)"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            type="number"
                                            name="compensation"
                                            component={
                                                Input
                                            }
                                            placeholder="Agent Compensation(%)"
                                            label="Agent Compensation(%)"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <DatePickerField
                                            name="validUntilDate"
                                            label="Valid Until Date"
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
