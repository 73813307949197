import * as requestFromServer from "./promoCodesCrud";
import {promoCodesSlice} from "./promoCodesSlice";
import {callTypes} from "../../Invoices/_redux/invoicesSlice";
const {actions} = promoCodesSlice;
export const addPromoCode = promoCode => dispatch => {
    return requestFromServer.addPromoCode(promoCode).then(response => {
        dispatch(fetchPromoCodes());
    }, (error) => {
        if( error.response ) {
            alert(error.response.data.message)
        }
    })
}


export const fetchPromoCodes= (qp) => (dispatch,getState) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    const queryParams = qp === undefined ? getState().promoCodes.lastFilter : qp;
    return requestFromServer
        .getPromoCodes(queryParams)
        .then(response => {
            const entities = response.data.entities;
            const totalCount = response.data.totalCount;
            dispatch(actions.promoCodesFetched({entities, totalCount, lastFilter: queryParams }));
        })
};
