import axios from "axios";

export const INVOICES_URL = "admin/invoices";
export function getInvoices(filter) {
    return axios.get(INVOICES_URL , {
        params: {
            limit: filter.pageSize,
                offset: (filter.pageNumber - 1) * filter.pageSize,
        ...filter.filter
        }
    });
}

export function approveInvoice(invoiceId) {
    return axios.put(`${INVOICES_URL}/${invoiceId}/approve`);
}

export function declineInvoice(invoiceId) {
    return axios.put(`${INVOICES_URL}/${invoiceId}/decline`);
}
