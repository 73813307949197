import {Redirect, Switch} from "react-router-dom";
import React, { Suspense } from "react";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import {PromoCodesPage} from "./PromoCodesPage";
import {PromoCodesUserPage} from "./promocodes-user/PromoCodesUserPage";

export default function PromoCodesModule() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute path="/promo-codes/by-email/:email" component={PromoCodesUserPage} />
                <ContentRoute path="/promo-codes" component={PromoCodesPage} />

            </Switch>
        </Suspense>
    );
}
